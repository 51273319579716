import httpUtil from "@/utils/httpUtil";

/**获取个人佣金数据 */
export const queryCommissionRecord = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecord", params)


/**获取个人佣金详情数据 */
export const queryCommissionRecordDetail = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecordDetail", params)


/**获取员工佣金详情数据 */
export const queryCommissionRecordUser = async params => httpUtil.post("/api/crmPc/distributor/queryCommissionRecordUser", params)

/**获取员提现列表 */
export const queryWithdrawals = async params => httpUtil.post("/api/crmPc/distributor/queryWithdrawals", params)


/**佣金设置 */
export const updateSetting = async params => httpUtil.post("/api/crmPc/distributor/updateSetting", params)


/**设置详情 */
export const querySetting = async params => httpUtil.post("/api/crmPc/distributor/querySetting", params)


/**提现操作 */
export const makeMoney = async params => httpUtil.post("/api/crmPc/distributor/makeMoney", params)





